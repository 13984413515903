import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./layouts/NavBar";
import Footer from "./layouts/Footer";
import Spinner from "./layouts/Spinner";
import ComingSoon from "./pages/comingSoon/ComingSoon";
import ErrorPage from "./pages/ErrorPage";
import RapportExpertise from "./pages/RapportExpertise";
import Dropdown from './components/Dropdown';

const Home = lazy(() => import("./pages/Home"));
const ContactSection = lazy(() => import("./pages/Contact"));
const About = lazy(() => import("./pages/About"));
const SiteIndustriels = lazy(() => import("./pages/SitesIndustriels"));
const Terrains = lazy(() => import("./pages/Terrains"));
const Immobilier = lazy(() => import("./pages/ImmobilierResidentiel"));
const Showrooms = lazy(() => import("./pages/Showroom"));
const LocauxCommerciaux = lazy(() => import("./pages/LocauxCommerciaux"));
const Bureaux = lazy(() => import("./pages/Bureaux"));
const Autre = lazy(() => import("./pages/Autre"));
const Services = lazy(() => import("./pages/Services"));
const Biens = lazy(() => import("./pages/Biens"));
const Programme = lazy(() => import("./pages/Programme"));
const Investisseurs = lazy(() => import("./pages/Investisseurs"));
const PackETI = lazy(() => import("./pages/PackETI"));
const PACKLocation = lazy(() => import("./pages/PACKLocation"));
const PACKPME = lazy(() => import("./pages/PACKPME"));
const PACKPMELOCATION = lazy(() => import("./pages/PACKPMELOCATION"));
const Marketing = lazy(() => import("./pages/Marketing"));
const Actualites = lazy(() => import("./pages/Actualites"));
const ArticleDetail = lazy(() => import("./pages/ArticleDetail"));
const Carte = lazy(() => import("./pages/Carte"));
const PropertyDetails  = lazy(() => import("./pages/PropertyDetails"));
const PageTypeRapport  = lazy(() => import("./pages/PageTypeRapport"));
const MainInfoRapport  = lazy(() => import("./pages/MainInfoRapport"));
const Paiement  = lazy(() => import("./pages/Paiement"));
const Confirmation = lazy(() => import("./pages/Confirmation"));




function App() {
  return (
    <div className="page-wrapper">
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </div>
  );
}

function AppContent() {

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/coming-soon" element={<ComingSoon />} />
      </Routes>
      {loading ? (
        <Spinner />
      ) : (
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/Nos-sites-industriels" element={<SiteIndustriels />} />
            <Route path="/Terrains" element={<Terrains />} />
            <Route path="/Immobilier-residentiel" element={<Immobilier />} />
            <Route path="/Showrooms" element={<Showrooms />} />
            <Route path="/Locaux-commerciaux" element={<LocauxCommerciaux />} />
            <Route path="/Bureaux" element={<Bureaux />} />
            <Route path="/Autre-biens" element={<Autre />} />
            <Route path="/Biens" element={<Biens />} />
            <Route path="/Nos-services" element={<Services />} />
            <Route path="/Nos-programme" element={<Programme />} />
            <Route path="/Pack-investisseurs" element={<Investisseurs />} />
            <Route path="/Pack-ETI-GE" element={<PackETI />} />
            <Route path="/Pack-ETI-GE-location" element={<PACKLocation />} />
            <Route path="/Pack-PME" element={<PACKPME />} />
            <Route path="/Pack-PME-location" element={<PACKPMELOCATION />} />
            <Route path="Marketing-digitale" element={<Marketing />} />
            <Route path="/Dernières-actualites" element={<Actualites />} /> 
            <Route path="/Articles/:id" Component={ArticleDetail} /> 
            <Route path="/contact-us" element={<ContactSection />} />
            <Route path="/Carte" element={<Carte/>}/>
            <Route path="/Expertiser-mon-bien" element={<PropertyDetails/>}/>
            <Route path="/Expertiser-mon-bien/adresse" element={<RapportExpertise/>}/>
            <Route path="/Expertiser-mon-bien/type-bien" element={<PageTypeRapport/>}/>
            <Route path="/Expertiser-mon-bien/main-info" element={<MainInfoRapport/>}/>
            <Route path="/Expertiser-mon-bien/main-info/paiement" element={<Paiement/>}/>
            <Route path="/Expertiser-mon-bien/main-info/paiement/confirmation" element={<Confirmation/>} />
            <Route path="*" element={<ErrorPage />} />

            </Routes>
          <Footer />
        </Suspense>
      )}
    </>
  );
}

export default App;

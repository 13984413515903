import React, { useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const Dropdown = ({ onCitySelect, activeCity }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    const city = event.target.value;
    setSelectedOption(city);
    onCitySelect(city);
  };

  const cities = ['Tanger', 'Rabat', 'Tetuan', "M'diq", 'Martil', 'Marrakech', 'Agadir', 'Nadour', 'Oujda'];

  return (
    <Box 
      sx={{ 
        minWidth: 120,
        maxWidth: 240,
        width: '100%',
        padding: 1,
        margin: 'auto'
      }} 
      className="drop"
    >
      <FormControl fullWidth className='frm'>
        <InputLabel id="city-select-label">Villes</InputLabel>
        <Select
          labelId="city-select-label"
          id="city-select"
          value={selectedOption}
          label="Villes"
          onChange={handleChange}
        >
          {cities.map((city) => (
            <MenuItem 
              key={city} 
              value={city.toLowerCase() === 'tanger' ? 'zones-layer' : city.toLowerCase().replace("'", "") + '-layer'}
              className={`city-pill ${activeCity === (city.toLowerCase() === 'tanger' ? 'zones-layer' : city.toLowerCase().replace("'", "") + '-layer') ? 'active' : ''}`}
            >
              {city}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Dropdown;

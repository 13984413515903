import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import "./menu.css";
import { Dropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDesktopMenu = () => {
    setIsDesktopMenuOpen(!isDesktopMenuOpen);
  };

  const closeMenus = () => {
    setIsMobileMenuOpen(false);
    setIsDesktopMenuOpen(false);
    setOpenDropdown(null);
  };

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };


  const location = useLocation();
  const [headerStyle, setHeaderStyle] = useState({

    backgroundColor: 'transparent',
  });

  const pathsToCheck = [
    '/',
    '/about-us',
    '/Nos-sites-industriels',
    '/Terrains',
    '/Immobilier-residentiel',
    '/Showrooms',
    '/Locaux-commerciaux',
    '/Bureaux',
    '/Autre-biens',
    '/Biens',
    '/Nos-services',
    '/Nos-programme',
    '/Pack-investisseurs',
    '/Pack-ETI-GE',
    '/Pack-ETI-GE-location',
    '/Pack-PME',
    '/Pack-PME-location',
    '/Marketing-digitale',
    '/Actualites',
    '/Articles/1',
    '/Articles/2',
    '/Articles/3',
    '/contact-us',
    '/coming-soon'
  ];


  useEffect(() => {

    if (!pathsToCheck.includes(location.pathname) || location.pathname === ('/Actualites') || location.pathname === ('/Carte')) {
      setHeaderStyle({
        ...headerStyle,
        backgroundColor: '#121827',
      });
    } else {
      setHeaderStyle({
        ...headerStyle,
        backgroundColor: 'transparent',
      });
    }
  }, [location.pathname]);

  return (
    <div className="pbmit-header-overlay">
      <div className="site-header-menu" style={headerStyle}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between pbmit-header-content">
            <div className="site-branding pbmit-logo-area">
              <h1 className="site-title">
                <Link to="/" onClick={closeMenus}>
                  <img
                    className="logo-img"
                    src="https://cdn.jsdelivr.net/gh/Soufiane-Boukhar/cdn/assets/images/logoWhite.png"
                    alt="Logo"
                    title="logo image"
                  />
                </Link>
              </h1>
            </div>
            {/* Desktop Menu */}
            <div className="site-navigation d-none d-xl-block">
              <nav className="main-menu">
                <ul className="navigation">
                  <li>
                    <Link to="/">ACCUEIL</Link>
                  </li>
                  <li>
                    <Link to="/about-us">À PROPOS DE NOUS</Link>
                  </li>
                  <li className="dropdown">
                    <Link>BIENS PROFESSIONNELS</Link>
                    <ul>
                      <li>
                        <Link to="/Nos-sites-industriels">
                          SITES INDUSTRIELS
                        </Link>
                      </li>
                      <li>
                        <Link to="/Terrains">TERRAINS</Link>
                      </li>
                      <li>
                        <Link to="/Immobilier-residentiel">
                          IMMOBILIER RESIDENTIEL
                        </Link>
                      </li>
                      <li>
                        <Link to="/Showrooms">SHOWROOMS</Link>
                      </li>
                      <li>
                        <Link to="/Locaux-commerciaux">LOCAUX COMMERCIAUX</Link>
                      </li>
                      <li>
                        <Link to="/Bureaux">BUREAUX</Link>
                      </li>
                      <li>
                        <Link to="/Autre-biens">AUTRES</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/Nos-services">SERVICES</Link>
                  </li>
                  <li className="dropdown">
                    <Link onClick={closeMenus}>NOS OFFRES</Link>
                    <ul>
                      <li>
                        <Link to="/Nos-programme" onClick={closeMenus}>
                          Notre programme d’accompagnement
                        </Link>
                      </li>
                      <li>
                        <Link to="/Pack-investisseurs" onClick={closeMenus}>
                          Pack investisseurs – Acquisition
                        </Link>
                      </li>
                      <li>
                        <Link to="/Pack-ETI-GE" onClick={closeMenus}>
                          Pack ETI & GE – Acquisition
                        </Link>
                      </li>
                      <li>
                        <Link to="/Pack-ETI-GE-location" onClick={closeMenus}>
                          PACK ETI & GE – LOCATION
                        </Link>
                      </li>
                      <li>
                        <Link to="/Pack-PME" onClick={closeMenus}>
                          Pack PME – Acquisition
                        </Link>
                      </li>
                      <li>
                        <Link to="/Pack-PME-location" onClick={closeMenus}>
                          Pack PME – Location
                        </Link>
                      </li>
                      <li>
                        <Link to="/Marketing-digitale" onClick={closeMenus}>
                          Marketing Digitale
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <Link>ESTIMER</Link>
                    <ul>
                      <li>
                        <Link to="/Carte">
                          CARTE DES PRIX
                        </Link>
                      </li>
                      <li>
                        <Link to="/Expertiser-mon-bien">
                          EXPERTISER MON BIEN
                        </Link>
                      </li>

                    </ul>
                  </li>
                  <li>
                    <Link to="/Dernières-actualites">ACTUALITÉS</Link>
                  </li>


                </ul>
              </nav>
            </div>
            {/* Mobile Menu */}
            <div className="d-xl-none text-center">
              <nav className="main-menu">
                <button
                  className="navbar-toggler menuButton"
                  type="button"
                  onClick={toggleMobileMenu}
                >
                  <i className="pbmit-base-icon-menu-1"></i>
                </button>
                <div
                  className={`collapse navbar-collapse ${isMobileMenuOpen ? "show" : ""
                    }`}
                  id="pbmit-mobile-menu"
                >

                  <Link to="/" onClick={closeMenus}>
                    <img
                      className="logoMobile"
                      src="https://cdn.jsdelivr.net/gh/Soufiane-Boukhar/cdn/assets/images/logoWhite.png"
                      alt="Logo"
                      title="logo image"
                    />
                  </Link>

                  <div className="sec-head"></div>

                  <ul className="navigation">
                    <li>
                      <Link to="/" onClick={closeMenus}>
                        ACCUEIL
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-us" onClick={closeMenus}>
                        À PROPOS DE NOUS
                      </Link>
                    </li>
                    <li>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          id="dropdownMenuButton"
                          className="border-0"
                        >
                          BIENS PROFESSIONNELS
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="p-2">
                          <LinkContainer
                            to="/Nos-sites-industriels"
                            onClick={closeMenus}
                          >
                            <Dropdown.Item>SITES INDUSTRIELS</Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/Terrains" onClick={closeMenus}>
                            <Dropdown.Item>TERRAINS</Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer
                            to="/Immobilier-residentiel"
                            onClick={closeMenus}
                          >
                            <Dropdown.Item>
                              IMMOBILIER RESIDENTIEL
                            </Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/Showrooms" onClick={closeMenus}>
                            <Dropdown.Item>SHOWROOMS</Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer
                            to="/Locaux-commerciaux"
                            onClick={closeMenus}
                          >
                            <Dropdown.Item>LOCAUX COMMERCIAUX</Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/Bureaux" onClick={closeMenus}>
                            <Dropdown.Item>BUREAUX</Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/Autre-biens" onClick={closeMenus}>
                            <Dropdown.Item>AUTRE</Dropdown.Item>
                          </LinkContainer>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    <li>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          id="dropdownMenuButton"
                          className="border-0"
                        >
                          NOS OFFRES
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="p-2">
                          <LinkContainer to="/Nos-programme" onClick={closeMenus}>
                            <Dropdown.Item>
                              Notre programme d’accompagnement
                            </Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/Pack-investisseurs" onClick={closeMenus}>
                            <Dropdown.Item>
                              Pack investisseurs – Acquisition
                            </Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/Pack-ETI-GE" onClick={closeMenus}>
                            <Dropdown.Item>
                              Pack ETI & GE – Acquisition
                            </Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/Pack-ETI-GE-location" onClick={closeMenus}>
                            <Dropdown.Item>
                              PACK ETI & GE – LOCATION
                            </Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/Pack-PME" onClick={closeMenus}>
                            <Dropdown.Item>
                              Pack PME – Acquisition
                            </Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/Pack-PME-location" onClick={closeMenus}>
                            <Dropdown.Item>Pack PME – Location</Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/Marketing-digitale" onClick={closeMenus}>
                            <Dropdown.Item>Marketing Digitale</Dropdown.Item>
                          </LinkContainer>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>

                    <li>
                      <Link to="/Nos-services" onClick={closeMenus}>
                        SERVICES
                      </Link>
                    </li>
                    <li>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          id="dropdownMenuButton"
                          className="border-0"
                        >
                          ESTIMER
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="p-2">
                          <LinkContainer to="/Carte" onClick={closeMenus}>
                            <Dropdown.Item>
                              CARTE DES PRIX
                            </Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/Expertiser-mon-bien" onClick={closeMenus}>
                            <Dropdown.Item>
                              EXPERTISER MON BIEN
                            </Dropdown.Item>
                          </LinkContainer>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>

                    <li>
                      <Link to="/Dernières-actualites" onClick={closeMenus}>
                        ACTUALITÉS
                      </Link>
                    </li>
                   
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import { useLocation } from 'react-router-dom';

const ErrorPage = () => {

    const location = useLocation();

  useEffect(() => {
    if(location.pathname === '/'){
      document.querySelector('.page_404').style.display = "none";
    }else{
      document.querySelector('.page_404').style.display = "block";
    }
  })

    return (
        <section className="page_404">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 ">
                        <div className="col-sm-10 col-sm-offset-1  text-center">
                            <div className="four_zero_four_bg">
                                <h1 className="text-center ">404</h1>

                            </div>

                            <div className="contant_box_404">
                                <h3 className="h2 p-4">
                                    On dirait que tu es perdu la page que vous recherchez n'est pas disponible!
                                </h3>

                                <Link to="/" className="link_404">Aller à la page d'accueil</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ErrorPage;

import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="footer site-footer pbmit-bg-color-blackish">
        <div className="pbmit-footer-big-area-wrapper">
          <div className="">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-3 col-md-6">
                <div className="pbmit-footer-logo text-center">
                  <img
                    src="images/logoWhite.png"
                    className="img-fluid footerLogo"
                    alt="Logo image"
                    title="Logo image Cap alliance"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pbmit-footer-main-area">
          <div>
            <div className="pbmit-footer-widget-area">
              <div>
                <div className="row g-3 px-4 footerSection">
                  <div className="newsLetter">
                    <div className="widget">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <h3>
                          Abonnez-vous à notre newsletter ! Restez toujours en
                          contact !
                        </h3>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <form>
                          <div className="pbmit-footer-newsletter">
                            <input
                              type="email"
                              name="EMAIL"
                              placeholder="Entrez votre email"
                            />
                            <button className="pbmit-svg-btnFooter">
                              <i class="fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="widget">
                      <h2 className="widget-title">Notre Adresse</h2>
                      <div className="pbmit-contact-widget-lines">
                        7 Rue Mahatma Gandhi, Tanger 90000
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="widget">
                      <h2 className="widget-title">Aide</h2>
                      <div className="pbmit-contact-widget-lines">
                        <ul className="aideUl">
                          <li>
                            <Link to="/coming-soon">FAQ</Link>
                          </li>
                          <li>
                            <Link to="/coming-soon">Conditions Générales</Link>
                          </li>
                          <li>
                            <Link to="/coming-soon">Rapports</Link>
                          </li>
                          <li>
                            <Link to="/coming-soon">Documentation</Link>
                          </li>
                          <li>
                            <Link to="/coming-soon">Politique de Support</Link>
                          </li>
                          <li>
                            <Link to="/coming-soon">Confidentialité</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="widget">
                      <h2 className="widget-title">Contactez-nous</h2>
                      <div className="pbmit-contact-widget-lines">
                        <ul className="aideUl">
                          <li>
                            <a
                              className="footerEmail"
                              href="mailto:contact@capalliance.ma?subject=Subject%20Text&body=Body%20Text"
                            >
                              contact@capalliance.ma
                            </a>
                          </li>
                          <li>+212 5 31 10 69 99</li>
                          <li>+212 6 66 62 27 86</li>
                          <li>+212 6 63 46 47 17</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="widget">
                      <h2 className="widget-title">Nos réseaux sociaux</h2>
                      <div className="textwidget">
                        <ul className="pbmit-social-links">
                          <li className="pbmit-social-li pbmit-social-facebook">
                            <a
                              href="https://web.facebook.com/CapAllianc/?_rdc=1&_rdr"
                              target="_blank"
                              rel="noopener"
                            >
                              <span>
                                <i className="pbmit-base-icon-facebook-squared"></i>
                              </span>
                            </a>
                          </li>
                          <li className="pbmit-social-li pbmit-social-twitter">
                            <a
                              href="https://ma.linkedin.com/company/cap-alliance-maroc?trk=similar-pages"
                              target="_blank"
                              rel="noopener"
                            >
                              <span>
                                <i class="fa-brands fa-linkedin"></i>
                              </span>
                            </a>
                          </li>
                          <li className="pbmit-social-li pbmit-social-instagram">
                            <a
                              href="https://www.instagram.com/capalliance/"
                              target="_blank"
                              rel="noopener"
                            >
                              <span>
                                <i className="pbmit-base-icon-instagram"></i>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pbmit-footer-text-area">
              <div className="container">
                <div className="pbmit-footer-text-inner">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="pbmit-footer-copyright-text-area">
                        Copyright © 2023 <a href="#">CapAlliance</a>, Tous droits réservés.
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className=" pbmit-footer-menu-area">
                        <ul>
                          <li>
                            <a href="#">Terms & Conditions</a>
                          </li>
                          <li>
                            <a href="#">Privacy Policy</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

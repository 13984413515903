import "../App.css";
import Header from "../layouts/Header";
import React from "react";

function NavBar(){
    return (
        <>
        <header className="site-header header-style-2">
          <Header />
        </header>
        </>
    )
}

export default NavBar;

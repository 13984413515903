import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Typography, Select, MenuItem, TextField, InputLabel } from "@mui/material";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Helmet } from "react-helmet";


const RapportExpertise = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const initialAddressState = location.state || {
        ville: "Tanger",
        quartier: "",
        adresse: "",
        zoneType: "Unknown", // Default zoneType
    };

    const [address, setAddress] = useState(initialAddressState);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const mapContainerRef = useRef(null);
    const mapInstanceRef = useRef(null);
    const markerRef = useRef(null);

    useEffect(() => {
        fetch("https://cdn.jsdelivr.net/gh/Soufiane-Boukhar/cdn/data/cities.json")
            .then((response) => response.json())
            .then((data) => {
                const cityData = data.find((item) => item.name === "Tanger");
                if (cityData) {
                    setNeighborhoods(cityData.neighborhoods);
                }
            })
            .catch((error) => console.error("Error fetching data:", error));
    }, []);

    useEffect(() => {
        const createMap = () => {
            const selectedNeighborhood = neighborhoods.find(
                (neighborhood) => neighborhood.name === address.quartier
            );

            if (selectedNeighborhood && selectedNeighborhood.bbox) {
                if (mapInstanceRef.current) {
                    mapInstanceRef.current.remove();
                }

                mapboxgl.accessToken = 'pk.eyJ1IjoienlhZGNhcCIsImEiOiJjbHZlMWhhbWwwNHZvMmtsOTZkZWdrN211In0.EnLpUxiPtxBaUmEHUfGTQQ';
                const map = new mapboxgl.Map({
                    container: mapContainerRef.current,
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: [
                        (selectedNeighborhood.bbox[0] + selectedNeighborhood.bbox[2]) / 2,
                        (selectedNeighborhood.bbox[1] + selectedNeighborhood.bbox[3]) / 2
                    ],
                    zoom: 12,
                });

                map.addControl(new mapboxgl.NavigationControl());

                const marker = new mapboxgl.Marker({
                    draggable: true
                }).setLngLat([
                    (selectedNeighborhood.bbox[0] + selectedNeighborhood.bbox[2]) / 2,
                    (selectedNeighborhood.bbox[1] + selectedNeighborhood.bbox[3]) / 2
                ]).addTo(map);

                marker.on('dragend', () => {
                    const lngLat = marker.getLngLat();
                    reverseGeocode(lngLat);
                });

                markerRef.current = marker;
                mapInstanceRef.current = map;
            }
        };

        const removeMap = () => {
            if (mapInstanceRef.current) {
                mapInstanceRef.current.remove();
                mapInstanceRef.current = null;
            }
        };

        if (address.quartier) {
            createMap();
        } else {
            removeMap();
        }

        return () => {
            removeMap();
        };
    }, [address.quartier, neighborhoods]);

    useEffect(() => {
        const form = document.querySelector(".container-rapport form");
        if (form && mapContainerRef.current) {
            const addressInput = form.querySelector("input[name='adresse']");
            if (addressInput) {
                addressInput.parentNode.insertBefore(mapContainerRef.current, addressInput.nextSibling);
            }
        }

        return () => {
            if (form && mapContainerRef.current) {
                mapContainerRef.current.remove();
            }
        };
    }, [address.quartier, neighborhoods]);

    const reverseGeocode = (lngLat) => {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?access_token=${mapboxgl.accessToken}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.features && data.features.length > 0) {
                    const placeType = data.features[0];

                    console.log(placeType);

                    let zoneType = 'Unknown';

                    if (placeType === 'neighborhood') {
                        zoneType = 'Residential';
                    } else if (placeType === 'poi') {
                        zoneType = 'test';
                    }

                    const addressName = data.features[0].place_name;
                    setAddress(prevAddress => ({
                        ...prevAddress,
                        adresse: addressName,
                        zoneType: zoneType,
                    }));
                }
            })
            .catch(error => console.error('Error with reverse geocoding:', error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddress((prevAddress) => ({
            ...prevAddress,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate("/Expertiser-mon-bien/type-bien", { state: { address } });
    };

    const successButtonStyle = {
        backgroundColor: '#6C8FEA',
        color: 'white',
        '&:hover': {
            backgroundColor: '#45a049',
        },
    };

    return (
        <>
            <Helmet>
                <title>Rapport Expertise - Cap Alliance</title>
                <meta name="description" content="Générez un rapport d'expertise en sélectionnant votre quartier et en fournissant l'adresse de votre bien. Utilisez notre carte interactive pour voir la localisation exacte." />
                <meta name="keywords" content="rapport expertise, évaluation immobilière, carte interactive, quartier, adresse, Cap Alliance" />
            </Helmet>
            <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }} className="rapport">
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h2" fontWeight="bold" textAlign="center" mb={2} className="typoTitles">
                        Où est situé
                        <Typography component="span" style={{ color: '#6C8FEA' }} fontWeight="bold" className="typoTitles">
                            {" "}
                            votre
                        </Typography>{" "}
                        bien ?
                    </Typography>
                    <Typography align="center">
                        <i className="fa-solid fa-location-dot"></i>
                    </Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <form onSubmit={handleSubmit} className="container-rapport">
                        <Box sx={{ mb: 2 }}>
                            <TextField
                                type="text"
                                name="adresse"
                                value={address.ville}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                readOnly
                                label="Ville"
                                placeholder="Ville"
                            />
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <InputLabel id="quartier-label">Sélectionnez Votre Quartier</InputLabel>
                            <Select
                                labelId="quartier-label"
                                name="quartier"
                                value={address.quartier}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            >
                                {neighborhoods.map((neighborhood, index) => (
                                    <MenuItem key={index} value={neighborhood.name}>
                                        {neighborhood.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <TextField
                                type="text"
                                name="adresse"
                                value={address.adresse}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                readOnly
                                label="Adresse"
                                placeholder="Adresse"
                            />
                        </Box>

                        {address.quartier && (
                            <Box sx={{ mb: 2 }} ref={mapContainerRef} style={{ height: "400px" }} />
                        )}

                        <Button
                            type="submit"
                            className="btn suivant"
                            disabled={!address.adresse}
                            variant="contained"
                            style={successButtonStyle}
                            fullWidth
                        >
                            Suivant
                        </Button>
                    </form>
                </Box>
            </Box>
        </>

    );
};

export default RapportExpertise;

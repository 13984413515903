import React, { useEffect, useState } from 'react';
import './soon/vendor/bootstrap/css/bootstrap.min.css';
import './soon/fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import './soon/vendor/animate/animate.css';
import './soon/vendor/select2/select2.min.css';
import './soon/vendor/countdowntime/flipclock.css';
import './soon/css/util.css';
import './soon/css/main.css';
import 'https://cdn.jsdelivr.net/npm/@popperjs/core@2.10.2/dist/umd/popper.min.js';

import logo from "./logoWhite.png";

const ComingSoon = () => {
    const [countdown, setCountdown] = useState(20 * 24 * 60 * 60); 

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatCountdown = () => {
        const days = Math.floor(countdown / (24 * 60 * 60));
        const hours = Math.floor((countdown % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((countdown % (60 * 60)) / 60);
        const seconds = countdown % 60;
        return { days, hours, minutes, seconds };
    };

    const renderCountdownCard = (label, value) => {
        return (
            <div className="countdown-card">
                <span className="countdown-number">{value}</span>
                <span className="countdown-label">{label}</span>
            </div>
        );
    };

    const { days, hours, minutes, seconds } = formatCountdown();

    return (
        <div className="bg-img1 size1 overlay1 p-b-35 p-l-15 p-r-15" id='soon'>
            <div className="flex-col-c p-t-160 p-b-215 respon1">
                <div className="wrappic1">
                    <a href="#">
                        <img src={logo} alt="IMG" />
                    </a>
                </div>

                <h3 className="l1-txt1 txt-center p-t-30 p-b-100">
                    Coming Soon
                </h3>

                <div className="countdown">
                    {renderCountdownCard("Days", days)}
                    {renderCountdownCard("Hours", hours)}
                    {renderCountdownCard("Minutes", minutes)}
                    {renderCountdownCard("Seconds", seconds)}
                </div>
            </div>

            <div className="flex-w flex-c-m p-b-35">
                <a href="#" className="size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-5">
                    <i className="fa fa-facebook"></i>
                </a>

                <a href="#" className="size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-5">
                    <i className="fa fa-twitter"></i>
                </a>

                <a href="#" className="size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-5">
                    <i className="fa fa-youtube-play"></i>
                </a>
            </div>
        </div>
    );
};

export default ComingSoon;
